import { useState, useLayoutEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { MapInteractionCSS } from 'react-map-interaction';
import Loader from 'react-loader-spinner';
import BlockMap from '../components/BlockMap';
import Layout from '../components/Layout';
import SpZoomModalContent from '../components/SpZoomModalContent';
import sectionMapBg from '../images/section-map-bg.svg';
import { ReactComponent as SectionMap } from '../images/sectionMap.svg';
import noImagePath from '../images/no-image-path.png';
import noImageBlockMapPath from '../images/no-image-block-map-path.png';
import { Seat, Section } from '../utils/models';
import {
  MapMode,
  SectionImageMode,
  TICKET_PURCHASE_BTN,
  TICKET_PURCHASE_URL,
  MAP_PDF_URL,
} from '../utils/constants';
import { getMediaQuery } from '../utils/domUtil';

/** @var {number} クリック・タッチとして扱うタッチ移動量の閾値 */
const DRAG_MOVE_LIMIT = 6;

/** @var {boolean} タッチイベントが利用可能か */
const touchEventAvailable = 'ontouchend' in window;

/**
 * 共通球場図
 * @returns {JSX.Element}
 */
const Stadium = () => {
  /** @var {Object<HtmlElement>} 球場図SVG */
  const sectionMap = useRef(null);

  /** @var {Object<HtmlElement>} 球場図表示エリア (サイズ計算用) */
  const sectionMapArea = useRef(null);

  /** @var {Object<string>} 座席番号検索値 */
  const { blockId, rowId, seatId } = useParams();

  /** @var {Array{0: number, 1: Function}} 眺め・座席写真の表示状態 */
  const [imageMode, setImageMode] = useState(SectionImageMode.VIEW);

  /** @var {Array{0: boolean, 1: Function}} SPでモーダルを表示中か */
  const [isSpModalShown, setIsSpModalShown] = useState(false);

  /** @var {Array{0: boolean, 1: Function}} SPで球場図から調べるの操作説明を表示中か */
  const [isSpClickIntroductionShown, setIsSpClickIntroductionShown] =
    useState(true);

  /** @var {Array{0: number, 1: Function}} 球場図エリアの表示モード */
  const [mapMode, setMapMode] = useState(MapMode.DETAIL);

  const [value, setValue] = useState({
    scale: 1,
    translation: { x: 0, y: 0 },
  });

  /** @var {Array{0: boolean, 1: Function}} 球場図SVGの読み込みが完了しているか */
  const [isStadiumImageLoaded, setIsStadiumImageLoaded] = useState(false);

  /** @var {number} SP 画面スクロール可能か（眺め・座席写真 / 座席番号図） */
  const pageScroll =
    touchEventAvailable &&
    (mapMode === MapMode.SECTION_IMAGES || mapMode === MapMode.BLOCK_MAP);

  /** @var {Seat|null} 個席 (「球場図から調べる」場合はNULL) */
  const seat = useMemo(
    () => (seatId ? Seat.find(blockId, rowId, seatId) : null),
    [blockId, rowId, seatId]
  );

  /** @var {Array{0: Section, 1: Function}} 選択中の席種 */
  const [section, setSection] = useState(seat ? seat.section : null);

  /** @var {Array{0: Element, 1: Function}} 選択中の席種クリッカブルエリア */
  const [selectedSectionEl, setSelectedSectionEl] = useState(null);

  /** @var {Array{0: number, 1: Function}} 球場図ドラッグ中の移動幅 */
  const [dragMoveLength, setDragMoveLength] = useState(0);

  /**
   * 席種ハイライト表示を更新
   * @param {Element} selectEl 新しく選択した席種クリッカブルエリア
   */
  const selectSection = (sectionEl) => {
    if (selectedSectionEl) {
      selectedSectionEl.classList.remove('selected');
    }
    const newSectionEl =
      sectionEl && sectionEl.tagName === 'g' ? sectionEl : null;
    setSelectedSectionEl(newSectionEl);
    if (newSectionEl) {
      sectionEl.classList.add('selected');
    }
    setSelectedSectionEl(newSectionEl);
  };

  /**
   * 球場図クリック
   * @param {Event} e
   */
  const handleMapClick = (e) => {
    if (dragMoveLength <= DRAG_MOVE_LIMIT) {
      const sectionEl = e.target.parentNode;
      const code = sectionEl.dataset.name ?? sectionEl.id ?? '';
      const [newBlockId, newSectionId] = code.split('-');
      setSection(Section.find(newBlockId, newSectionId));
      selectSection(sectionEl);
    }
  };

  /**
   * クリック・タッチ開始時にドラッグ幅リセット
   */
  const handleTouchStart = () => {
    setDragMoveLength(0);
  };

  /**
   * クリック・タッチ中にドラッグ幅加算
   */
  const handleTouchMove = () => {
    if (dragMoveLength < DRAG_MOVE_LIMIT + 1) {
      setDragMoveLength(dragMoveLength + 1);
    }
  };

  /** @var {Object<Function>} 球場図クリック方法出しわけ */
  const mapClickType = touchEventAvailable
    ? {
        onTouchStart: handleTouchStart,
        onTouchMove: handleTouchMove,
        onTouchEnd: handleMapClick,
      }
    : {
        onMouseDown: handleTouchStart,
        onMouseMove: handleTouchMove,
        onMouseUp: handleMapClick,
      };

  // SP球場図タブ閉じる
  const closeTab = () => {
    setIsSpClickIntroductionShown(false);
  };

  // 眺め・座席切り替え
  const handlePhotoChange = (e) => {
    setImageMode(e.currentTarget.value);
  };

  // タブ切り替え
  const handleViewChange = (e) => {
    setMapMode(e.currentTarget.value);
    // モバイル幅の場合はモーダルを展開
    if (e.currentTarget.dataset.mq) {
      setIsSpModalShown(true);
    }
  };

  useLayoutEffect(() => {
    // 「座席番号から調べる」時にブロック内席種に枠をつけズームする
    if (seat && sectionMap) {
      const sectionEl = /^\d/.test(section.blockId)
        ? sectionMap.current.querySelector(`[data-name="${section.code}"]`)
        : sectionMap.current.getElementById(section.code);
      selectSection(sectionEl);

      // 表示座標計算
      const isSp = getMediaQuery() === 'sp';
      const scale = isSp ? 2.4 : 3;
      const areaRect = sectionMapArea.current.getBoundingClientRect();
      const svgRect = sectionMap.current.getBoundingClientRect();
      const scaleCorrection = scale / value.scale;
      const translation = {
        x: svgRect.width * scaleCorrection * -section.x + areaRect.width / 2,
        y: svgRect.height * scaleCorrection * -section.y + areaRect.height / 2,
      };

      // レスポンシブ比率補正
      if (isSp) {
        const breakPointCorrection =
          areaRect.height * 0.95 - areaRect.width - 96;
        const centerY = (-svgRect.height * scaleCorrection) / 2;
        if (breakPointCorrection > 0) {
          const rate = breakPointCorrection / 1200 + 0.28;
          translation.y -= (translation.y - centerY) * rate - 140;
        }
      }
      setValue({ scale, translation });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout pageSlug="stadium" pageScroll={pageScroll}>
      <div className={`inner ${!touchEventAvailable ? 'notTouchDevice' : ''}`}>
        <div className="container">
          <div
            className={`map-image ${
              mapMode === MapMode.DETAIL || mapMode === MapMode.ACCESS
                ? 'show'
                : ''
            }`}
            ref={sectionMapArea}
          >
            <MapInteractionCSS
              value={value}
              onChange={setValue}
              minScale={1}
              maxScale={5}
              showControls
              plusBtnClass="plus-map-btn"
              minusBtnClass="minus-map-btn"
              controlsClass="map-btn-wrap"
            >
              <img
                src={sectionMapBg}
                alt="ベルーナドーム球場図"
                className={`stadium-bg ${isStadiumImageLoaded ? 'show' : ''}`}
                onLoad={() => {
                  setIsStadiumImageLoaded(true);
                }}
              />
              <SectionMap
                ref={sectionMap}
                className={`section-map ${
                  !seat ? 'section-map-selectable' : ''
                }`}
                {...mapClickType}
              />
              <img
                className={`route ${
                  mapMode === MapMode.ACCESS ? 'active' : ''
                }`}
                src={section && section.routePath}
                alt="ベルーナドーム球場図ルート"
              />
            </MapInteractionCSS>
            <div
              className={`course ${mapMode === MapMode.ACCESS ? 'active' : ''}`}
            >
              <div className="course-inner">
                <div className="course-desc">
                  <span className="course-symbol" />
                  <span className="course-symbol" />
                  <span className="course-symbol" />
                  <div className="course-name">
                    <p>メインコンコース</p>
                    <p>(飲食店舗多数/上り坂/階段下り)</p>
                  </div>
                </div>
                <div className="course-desc">
                  <span className="course-symbol" />
                  <span className="course-symbol" />
                  <span className="course-symbol" />
                  <div className="course-name">
                    <p>サブコンコース</p>
                    <p>(飲食店舗少数/平坦/階段上りまたは下り)</p>
                  </div>
                </div>
              </div>
            </div>
            {!isStadiumImageLoaded ? (
              <div className="loading">
                <Loader type="Grid" color="#05204b" height={47} width={47} />
                <p className="loading-text">Now Loading...</p>
              </div>
            ) : null}
          </div>
          {mapMode === MapMode.SECTION_IMAGES ? (
            <div className="desc">
              <div className="desc-image">
                {section.imagePaths.map((imagePath, i) => (
                  <img
                    src={imagePath}
                    alt=""
                    key={imagePath}
                    className={imageMode === String(i) ? '' : 'inactive'}
                    onError={(e) => e.target.src === noImagePath}
                  />
                ))}
              </div>
              <div className="desc-text">
                {imageMode === SectionImageMode.VIEW ? (
                  <p className="listmark">
                    ※{section.blockId}
                    ブロック付近から見たイメージです。前後左右の座席位置によって眺めが異なりますのであらかじめご了承ください
                  </p>
                ) : (
                  <>
                    <p className="listmark">
                      ※シートイメージとなります。撮影場所と実際のシート位置が異なる場合がございますのであらかじめご了承ください。
                    </p>
                    {section.descriptions.view && (
                      <p className="listmark">※{section.descriptions.view}</p>
                    )}
                  </>
                )}
              </div>
              <div className="btn-wrap">
                <button
                  type="button"
                  className={`btn btn-regular ${
                    imageMode === SectionImageMode.VIEW ? 'active' : ''
                  }`}
                  value={SectionImageMode.VIEW}
                  onClick={handlePhotoChange}
                >
                  座席からの眺め
                </button>
                <button
                  type="button"
                  className={`btn btn-regular ${
                    imageMode === SectionImageMode.SEAT ? 'active' : ''
                  }`}
                  value={SectionImageMode.SEAT}
                  onClick={handlePhotoChange}
                >
                  座席写真
                </button>
              </div>
            </div>
          ) : null}
          {mapMode === MapMode.BLOCK_MAP ? (
            <div>
              <div className="desc pc">
                <div className="desc-image block-map">
                  <img
                    src={section.blockMapPath}
                    alt="座席番号図"
                    onError={(e) => {
                      e.target.src = noImageBlockMapPath;
                    }}
                  />
                </div>
              </div>
              <div className="desc sp">
                <BlockMap src={section.blockMapPath} />
              </div>
            </div>
          ) : null}
          <div className="tab">
            <ul className={`tab-wrap ${section ? 'active' : ''}`}>
              <li className={mapMode === MapMode.DETAIL ? 'active' : ''}>
                <button
                  type="button"
                  value={MapMode.DETAIL}
                  onClick={handleViewChange}
                >
                  座席位置
                  <br />
                  詳細
                </button>
              </li>
              <li className={mapMode === MapMode.ACCESS ? 'active' : ''}>
                <button
                  type="button"
                  value={MapMode.ACCESS}
                  onClick={handleViewChange}
                >
                  座席までの
                  <br />
                  アクセス
                </button>
              </li>
              <li
                className={mapMode === MapMode.SECTION_IMAGES ? 'active' : ''}
              >
                <button
                  type="button"
                  value={MapMode.SECTION_IMAGES}
                  onClick={(e) => handleViewChange(e)}
                >
                  眺め・座席
                  <br />
                  写真
                </button>
              </li>
              <li
                className={`sp ${
                  mapMode === MapMode.BLOCK_MAP ? 'active' : ''
                }`}
              >
                <button
                  type="button"
                  value={MapMode.BLOCK_MAP}
                  onClick={(e) => handleViewChange(e)}
                  data-mq="sp"
                >
                  座席番号
                </button>
              </li>
              <li
                className={`pc ${
                  mapMode === MapMode.BLOCK_MAP ? 'active' : ''
                }`}
              >
                <button
                  type="button"
                  value={MapMode.BLOCK_MAP}
                  onClick={(e) => handleViewChange(e)}
                >
                  座席番号
                </button>
              </li>
            </ul>
            {section ? (
              <div className="tab-select">
                <div className="box">
                  <p className="select-label">選択中</p>
                  <div className="box-text">
                    <h3 className="type">{section.name}</h3>
                    <div className="select-desc">
                      {section.blockId && (
                        <p className="select-desc-item">
                          {section.blockId}ブロック
                        </p>
                      )}
                      <p className="select-desc-item">
                        {seat && `${seat.rowId}段`}
                      </p>
                      <p className="select-desc-item">
                        {seat && `${seat.id}番`}
                      </p>
                    </div>
                    {section.descriptions.section && (
                      <p className="listmark attention select-description">
                        ※{section.descriptions.section}
                      </p>
                    )}
                    {section.descriptions.section2 && (
                      <p className="listmark attention select-description">
                        ※{section.descriptions.section2}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div
                className={`tab-search ${
                  isSpClickIntroductionShown ? '' : 'close'
                }`}
              >
                <button
                  type="button"
                  aria-label="タブを閉じる"
                  onClick={closeTab}
                  className="modal-close sp"
                />
                <div className="box-text">
                  <h3 className="box-text-ttl">球場図から調べる</h3>
                  <div className="text">
                    <p>
                      球場図を拡大して調べたいエリアをクリックしてください。クリックしたブロックの情報、座席までのアクセス、席からの眺め等をご確認いただけます。
                    </p>
                    <p className="listmark attention">
                      ※埼玉西武ライオンズは三塁側ベンチを使用します
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="link-wrap">
          <ul>
            <li>
              <a
                href="https://www.seibulions.jp/stadium/access/"
                target="_blank"
                rel="noopener"
              >
                ベルーナドームへのアクセスはこちら
              </a>
            </li>
            {MAP_PDF_URL && (
              <li>
                <a href={MAP_PDF_URL} target="_blank" rel="noopener">
                  ベルーナドーム座席図PDF DOWNLOAD
                </a>
              </li>
            )}
            {TICKET_PURCHASE_BTN && (
              <li>
                <a href={TICKET_PURCHASE_URL} target="_blank" rel="noopener">
                  チケットの購入はこちら
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
      <SpZoomModalContent
        isSpModalShown={isSpModalShown}
        setIsSpModalShown={setIsSpModalShown}
      />
    </Layout>
  );
};

export default Stadium;
