// eslint-disable-next-line max-classes-per-file
import { fetchData, deepFetch, getBlockIdAlias } from './dataFetch';
import blocks from '../data/blocks.json';
import sectionNames from '../data/sectionNames.json';
import sectionPositions from '../data/sectionPositions.json';
import sectionDescriptions from '../data/sectionDescriptions.json';

/**
 * 席種
 * @param {string} blockId ブロックID
 * @param {string} sectionId 席種ID
 * @param {boolean} forceCreate IDの整合性をチェックしない
 * @constructor
 */
class Section {
  constructor(blockId, sectionId) {
    const [x = 0, y = 0] =
      deepFetch(sectionPositions, blockId, sectionId) ?? [];
    /** @property {string} 席種ID */
    this.id = sectionId;
    /** @property {string} ブロックID (エイリアスIDを優先) */
    this.blockId = getBlockIdAlias(blockId) ?? blockId;
    /** @property {string} 内部ブロックID */
    this.blockDataId = blockId;
    /** @property {string} 席種識別コード */
    this.code = `${blockId}-${sectionId}`;
    /** @property {string} 席種名 */
    this.name = sectionNames[sectionId] ?? '';
    /** @property {Object} 特記事項リスト */
    this.descriptions = sectionDescriptions[this.code] ?? {};
    /** @property {string} ブロック席種・横 */
    this.x = x;
    /** @property {string} ブロック席種・縦 */
    this.y = y;
    /** @property {Array.<string>} 席種画像パスリスト */
    this.imagePaths = [this.imagePath('01.jpg'), this.imagePath('02.jpg')];
    /** @property {string} ルート指示画像パス */
    this.routePath = `/images/section-routes/${this.code}.svg`;
    /** @property {string} ブロック席番号図パス */
    this.blockMapPath = `/images/block-maps/${this.blockDataId}.png`;
  }

  /**
   * データを検索してモデルインスタンスを作成
   * @param {string} blockId ブロックID
   * @param {string} sectionId 席種ID
   * @returns {Section|null}
   */
  static find(blockId, sectionId) {
    return deepFetch(blocks, blockId, sectionId)
      ? new Section(blockId, sectionId)
      : null;
  }

  /**
   * 席種画像のパスを取得
   * @param fileName
   * @returns {string}
   */
  imagePath(fileName) {
    return `/images/section-images/${this.blockDataId}-${this.id}/${fileName}`;
  }
}

/**
 * 席種
 * @param {string} blockId ブロックID
 * @param {string} rowId 段数
 * @param {string} seatId 座席番号
 * @constructor
 */
class Seat {
  constructor(blockId, sectionId, rowId, seatId) {
    this.id = seatId;
    this.blockId = blockId;
    this.sectionId = sectionId;
    this.rowId = rowId;
    this.section = new Section(blockId, sectionId);
  }

  /**
   * データを検索してモデルインスタンスを作成する
   * @param {*} inputBlockId ブロックIDの入力値
   * @param {*} rowId 段数
   * @param {*} seatId 座席番号
   * @returns {Seat}
   */
  static find(inputBlockId, rowId, seatId) {
    const { blockId, sectionId } = fetchData(inputBlockId, rowId, seatId);
    return sectionId ? new Seat(blockId, sectionId, rowId, seatId) : null;
  }
}

export { Section, Seat };
