/**
 * body固定制御
 * @param {boolean} force スクロールを固定するか
 */
export const toggleBodyFixed = (force = null) => {
  document.body.classList.toggle('body-fixed', force);
};

/**
 * 擬似要素を利用してメディアクエリの表示状態を取得
 * @returns {string}
 */
export const getMediaQuery = () =>
  window
    .getComputedStyle(document.body, '::after')
    .getPropertyValue('content')
    .replace(/"/g, '');
