import * as React from 'react';
import { Link } from 'react-router-dom';
import icon1 from '../images/icon-lions.svg';
import { MAINTENANCE_MODE } from '../utils/constants';

const Footer = () => (
  <footer>
    <div className="footer-inner">
      <h1 className="footer-ttl">
        <a href="https://www.seibulions.jp/" target="_blank" rel="noopener">
          <img src={icon1} alt="埼玉西武ライオンズ" />
          <span>埼玉西武ライオンズ オフィシャルサイト</span>
        </a>
      </h1>
      <div className="footer-right">
        {!MAINTENANCE_MODE && (
          <ul className="footer-list">
            <li>
              <Link to="/Environment">推奨環境</Link>
            </li>
          </ul>
        )}
        <div className="copyright">
          Copyright &copy; SEIBU Lions All Rights Reserved.
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
