import Layout from '../components/Layout';

const NotFound = () => (
  <Layout>
    <section>
      <div className="inner under-page">
        <h2 className="ttl-header">お探しのページは見つかりません。</h2>
        <ul className="under-wrap">
          <li>
            <p className="tac">
              お探しのページは一時的にアクセスができない状況にあるか、移動もしくは削除された可能性があります。
            </p>
          </li>
        </ul>
      </div>
    </section>
  </Layout>
);

export default NotFound;
