import * as React from 'react';
import Header from './Header';
import Footer from './Footer';

const Layout = ({ children, pageSlug, pageScroll }) => (
  <div
    className={`wrapper ${pageSlug ? `page-${pageSlug}` : ''} ${
      pageScroll ? 'pageScroll' : ''
    }`}
  >
    <Header />
    <main>{children}</main>
    <Footer />
  </div>
);

export default Layout;
