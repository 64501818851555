/** @var {boolean} メンテナンスモードのON/OFF */
export const MAINTENANCE_MODE = false;

/** @var {Object} 席種情報タブ表示モード */
export const MapMode = {
  DETAIL: 'DETAIL',
  ACCESS: 'ACCESS',
  SECTION_IMAGES: 'SECTION_IMAGES',
  BLOCK_MAP: 'BLOCK_MAP',
};

/** @var {Object} 球場図部表示モード */
export const SectionImageMode = {
  VIEW: '0',
  SEAT: '1',
};

/** @var {boolean} チケット購入ボタン表示モードのON/OFF */
export const TICKET_PURCHASE_BTN = true;

/** @var {String} チケット購入ボタンURL */
export const TICKET_PURCHASE_URL = 'https://ticket.seibulions.jp/';

/**
 * @var {String} ベルーナドーム座席図PDF DOWNLOAD URL
 * 「ベルーナドーム座席図PDF DOWNLOAD」ボタン非表示の場合は空文字
 */
export const MAP_PDF_URL =
  'https://www.seibulions.jp/images/ticket/dome/seat_2024.pdf';
