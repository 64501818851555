import Layout from '../components/Layout';

const Environment = () => (
  <Layout>
    <section>
      <div className="inner under-page">
        <h2 className="ttl-header">推奨環境</h2>
        <ul className="under-wrap">
          <li>
            <p>
              当サービスを快適にご利用いただくために、以下の環境でのご利用を推奨いたします。
            </p>
            <p>また、JavaScript、Cookieが使用できる状態でご利用ください。</p>
            <h4 className="ttl-sub3 mt15">推奨環境</h4>
            <table className="tbl-del">
              <tbody>
                <tr>
                  <th> </th>
                  <th>対象OS</th>
                  <th>対象ブラウザ</th>
                </tr>
                <tr>
                  <td>Windows</td>
                  <td>Windows10</td>
                  <td>
                    Microsoft Edge 最新版
                    <br />
                    Google Chrome 最新版
                  </td>
                </tr>
                <tr>
                  <td>Mac OS</td>
                  <td>10.13High Sierra 以上</td>
                  <td>
                    Safari 最新版
                    <br />
                    Google Chrome 最新版
                  </td>
                </tr>
                <tr>
                  <td>iOS</td>
                  <td>iOS12 以上</td>
                  <td>Safari 最新版</td>
                </tr>
                <tr>
                  <td>Android</td>
                  <td>Android 6 以上</td>
                  <td>Google Chrome 最新版</td>
                </tr>
              </tbody>
            </table>
          </li>
          <li>
            <h3 className="ttl-sub2">＜注意事項＞</h3>
            <p className="listmark">
              <span className="attention">※</span>
              推奨する上記の環境であっても、お客様が使用するブラウザのセキュリティ設定や、セキュリティソフトのご利用状況によっては本サービスをご利用いただけない場合があります
            </p>
            <p className="listmark">
              <span className="attention">※</span>
              文字サイズ変更などのズーム機能を利用すると、一部表示が崩れたりする場合があります
            </p>
            <p className="listmark">
              <span className="attention">※</span>
              上記以外の環境では、ページが正常に表示されない場合があります
            </p>
            <p className="listmark">
              <span className="attention">※</span>
              本サービスでは、従来型携帯電話（ガラパゴス携帯・フィーチャーフォン）表示の対応はしておりません
            </p>
          </li>
        </ul>
      </div>
    </section>
  </Layout>
);

export default Environment;
