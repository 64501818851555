import { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { toggleBodyFixed } from '../utils/domUtil';
import Input from './Input';
import wheelchairBlockIds from '../data/wheelchairBlockIds.json';
import { Seat } from '../utils/models';
import { getBlockIdAlias } from '../utils/dataFetch';

// TOP 検索モーダル
const SearchModalContent = ({ setIsShown }) => {
  const history = useHistory();
  const [blockId, setBlockId] = useState('');
  const [rowId, setRowId] = useState('');
  const [seatId, setSeatId] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isWheelchair, setIsWheelchair] = useState(false);

  /**
   * モーダルを閉じる
   */
  const closeModal = () => {
    setIsShown(false);
    toggleBodyFixed(false);
  };

  /**
   * ブロックID変更時
   * @param {Event} e
   */
  const handleBlockIdChange = (e) => {
    setBlockId(e.currentTarget.value);
  };

  /**
   * 車椅子席の切替
   */
  const toggleIsWheelchair = () => {
    const checked = !isWheelchair;
    setIsWheelchair(checked);
    if (checked) {
      setBlockId('');
    }
  };

  // モーダル展開時にスクロールを固定
  useEffect(() => {
    toggleBodyFixed(true);
  }, []);

  const handleSearch = () => {
    if (rowId === '') {
      return setErrorMessage('段数を入力してください');
    }
    if (seatId === '') {
      return setErrorMessage('席番号を入力してください');
    }

    // 個席情報を取得 (内部IDでの検索不可)
    const seat =
      getBlockIdAlias(blockId) === null
        ? Seat.find(blockId, rowId, seatId)
        : null;
    if (!seat) {
      return setErrorMessage('指定された席が見つかりません');
    }

    // スクロール固定解除
    toggleBodyFixed(false);

    // リダイレクト
    setTimeout(() => {
      history.push(`/seat/${seat.blockId}/${seat.rowId}/${seat.id}`);
    }, 600);

    return setErrorMessage('');
  };

  return (
    <div className="searchModal is-active">
      <div
        className="searchModal-background"
        onClick={closeModal}
        onKeyDown={closeModal}
        role="button"
        aria-label="閉じる"
        tabIndex="0"
      />
      <div className="searchModal-inner">
        <button
          type="button"
          onClick={closeModal}
          className="modal-close"
          aria-label="モーダルを閉じる"
        />
        <h3 className="searchModal-ttl">座席番号から調べる</h3>
        <p className="searchModal-text">
          座席番号から検索することができます。お手持ちのチケットに記載されている情報を入力してください。
        </p>
        <div className="searchModal-box">
          <div className="box">
            <p className="box-ttl">ブロック</p>
            {isWheelchair ? (
              <select
                className="box-select"
                value={blockId}
                onChange={handleBlockIdChange}
              >
                {wheelchairBlockIds.map((wheelchairBlockId) => (
                  <option value={wheelchairBlockId} key={wheelchairBlockId}>
                    {wheelchairBlockId || 'なし'}
                  </option>
                ))}
              </select>
            ) : (
              <Input id={blockId} setId={setBlockId} />
            )}
          </div>
          <div className="box">
            <p className="box-ttl">段</p>
            <Input id={rowId} setId={setRowId} />
          </div>
          <div className="box">
            <p className="box-ttl">座席</p>
            <Input id={seatId} setId={setSeatId} />
          </div>
          <label className="wheelchair" htmlFor="checkWheel">
            <input
              type="checkbox"
              checked={isWheelchair}
              onChange={toggleIsWheelchair}
              id="checkWheel"
            />
            車椅子席へのルートを検索される場合は左記のチェックボックスをクリックしてください
          </label>
          <div className="btn-wrap">
            <button
              type="button"
              onClick={handleSearch}
              className="btn btn-search"
            >
              検索
            </button>
          </div>
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>
      </div>
    </div>
  );
};

export default SearchModalContent;
