import { Link, useLocation } from 'react-router-dom';
import ticket from '../images/icon-ticket.svg';
import stadium from '../images/icon-stadium.svg';
import icon from '../images/icon-lions.svg';
import { MAINTENANCE_MODE } from '../utils/constants';

const Header = () => {
  const location = useLocation();
  return (
    <header>
      <div className="header-inner">
        <h1 className="header-ttl">
          <Link to="/">
            <img src={icon} alt="埼玉西武ライオンズ" />
            <span>埼玉西武ライオンズ座席案内</span>
          </Link>
        </h1>
        {!MAINTENANCE_MODE && (
          <nav>
            <ul>
              <li>
                <Link
                  to={{
                    pathname: '/',
                    state: { isModalShown: true },
                  }}
                >
                  <img src={ticket} alt="座席番号から調べる" />
                  <p className="pc">座席番号から調べる</p>
                  <p className="sp">番号検索</p>
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: '/seat',
                    state: {
                      refresh: !location.state || !location.state.refresh,
                    },
                  }}
                  replace={location.pathname === '/seat'}
                >
                  <img src={stadium} alt="球場図から調べる" />
                  <p className="pc">球場図から調べる</p>
                  <p className="sp">球場図検索</p>
                </Link>
              </li>
            </ul>
          </nav>
        )}
      </div>
    </header>
  );
};

export default Header;
