import { useState, useLayoutEffect } from 'react';
import Cookies from 'js-cookie';
import zoom from '../images/zoom.png';

/** @var {boolean} タッチイベントが利用可能か */
const touchEventAvailable = 'ontouchend' in window;

/**
 * SP球場図初回説明モーダル
 * @param {Function} isSpModalShown
 * @param {Function} setIsSpModalShown
 */
const SpZoomModalContent = ({ isSpModalShown, setIsSpModalShown }) => {
  /** @var {Array{0: boolean, 1: Function}} モバイルでの初回説明を表示済か */
  const [hasIntroductionShown, setHasIntroductionShown] = useState(false);

  /** @var {Array{0: boolean, 1: Function}} すべての説明を表示済か */
  const [hasAllModalViewed, setHasAllModalViewed] = useState(false);

  // Cookieを利用して初回説明の表示状況を設定
  useLayoutEffect(() => {
    const newHasIntroductionShown = !!Cookies.get('zoomModalFirst');
    setHasIntroductionShown(newHasIntroductionShown);
    setHasAllModalViewed(Cookies.get('zoomModalAll'));
    setIsSpModalShown(!newHasIntroductionShown);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // すべての説明を表示済の場合はなにも表示しない
  if (isSpModalShown || hasAllModalViewed) {
    return null;
  }

  /**
   * モーダル閉じる
   */
  const onCloseModal = () => {
    // ズームモーダルクッキー設定
    if (!hasIntroductionShown) {
      // 球場図ズームモーダル 表示済みクッキー
      Cookies.set('zoomModalFirst', '1', { expires: 0.5 });
    } else {
      // 球場図・座席番号図ズームモーダル 両方表示済みクッキー
      Cookies.set('zoomModalAll', '1', { expires: 0.5 });
      setHasAllModalViewed(true);
    }
    setHasIntroductionShown(true);
    setIsSpModalShown(false);
  };

  return (
    <div className={`spZoomModal ${touchEventAvailable ? 'is-active' : ''}`}>
      <div
        className="searchModal-background"
        onClick={onCloseModal}
        onKeyDown={onCloseModal}
        role="button"
        aria-label="閉じる"
        tabIndex="0"
      />
      <div className="spZoomModal-inner">
        <button
          type="button"
          aria-label="モーダルを閉じる"
          onClick={onCloseModal}
          className="modal-close"
        />
        <div className="text-inner">
          {!hasIntroductionShown ? (
            <p>
              球場図はピンチイン・ピンチアウトで
              <br />
              拡大縮小ができます。
            </p>
          ) : (
            <p>
              座席番号図はピンチイン・ピンチアウトで
              <br />
              拡大縮小ができます。
            </p>
          )}
        </div>
        <img src={zoom} alt="ピンチインピンチアウト" />
      </div>
    </div>
  );
};

export default SpZoomModalContent;
