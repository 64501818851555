import { useState, useRef } from 'react';
import { MapInteractionCSS } from 'react-map-interaction';
import noImageBlockMapPath from '../images/no-image-block-map-path.png';

/**
 * 拡大可能な座席番号図
 * @param src
 * @returns {JSX.Element}
 */
const BlockMap = ({ src }) => {
  const image = useRef(null);

  /** @var MapIntersectionの状態 */
  const [mapInteractionValue, setMapInteractionValue] = useState({
    scale: 1,
    translation: { x: 0, y: 0 },
  });

  /** @var {Array{0: boolean, 1: Function}} 画像に読み込みエラーが発生したか */
  const [isError, setIsError] = useState(false);

  /**
   * 画像読み込みエラー発生時
   */
  const handleError = () => {
    setIsError(true);
  };

  /**
   * 画像が画面外にはみ出さないようにデータ更新
   * @param scale
   * @param translation
   */
  const handleChange = ({ scale, translation }) => {
    const newValue = { scale, translation };
    const imageContainer = image.current.parentNode;
    const rate = scale - 1;
    if (translation.x > 0) {
      newValue.translation.x = 0;
    } else if (translation.x < -imageContainer.clientWidth * rate) {
      newValue.translation.x = -imageContainer.clientWidth * rate;
    }
    if (translation.y > 0) {
      newValue.translation.y = 0;
    } else if (translation.y < -imageContainer.clientHeight * rate) {
      newValue.translation.y = -imageContainer.clientHeight * rate;
    }
    return setMapInteractionValue(newValue);
  };

  return (
    <MapInteractionCSS
      value={mapInteractionValue}
      onChange={handleChange}
      minScale={1}
      maxScale={3}
    >
      <img
        ref={image}
        src={!isError ? src : noImageBlockMapPath}
        alt="座席番号図"
        onError={handleError}
      />
    </MapInteractionCSS>
  );
};

export default BlockMap;
