import { HashRouter, Route, Switch } from 'react-router-dom';
import Teaser from './screen/Teaser';
import Home from './screen/Home';
import Stadium from './screen/Stadium';
import Environment from './screen/Environment';
import Edit from './screen/Edit';
import NotFound from './screen/NotFound';
import { MAINTENANCE_MODE } from './utils/constants';
import ScrollToTop from './utils/ScrollToTop';

const App = () => (
  <HashRouter>
    <ScrollToTop />
    {MAINTENANCE_MODE ? (
      <Switch>
        <Route exact path="/" component={Teaser} />
        <Route component={NotFound} />
      </Switch>
    ) : (
      <Switch>
        <Route exact path="/" component={Home} />
        <Route
          exact
          key="search"
          path="/seat/:blockId/:rowId/:seatId"
          component={Stadium}
        />
        <Route
          exact
          key="click"
          path="/seat"
          render={({ location }) => {
            const key =
              location.state && location.state.refresh
                ? 'click-refresh'
                : 'click';
            return <Stadium key={key} />;
          }}
        />
        <Route exact path="/edit" component={Edit} />
        <Route exact path="/environment" component={Environment} />
        <Route component={NotFound} />
      </Switch>
    )}
  </HashRouter>
);

export default App;
