import update from 'immutability-helper';
import { useState, useEffect, useLayoutEffect, useRef } from 'react';
import { MapInteractionCSS } from 'react-map-interaction';
import { useHistory } from 'react-router-dom';
import Layout from '../components/Layout';
import sectionPositions from '../data/sectionPositions.json';
import { ReactComponent as SectionMap } from '../images/sectionMap.svg';
import sectionMapBg from '../images/section-map-bg.svg';
import { deepFetch, deepInit } from '../utils/dataFetch';
import { Section } from '../utils/models';
import { getMediaQuery } from '../utils/domUtil';

/** @var {number} 拡大時の標準倍率 */
const FOCUS_SCALE = 3;

/**
 * ブロック内席種座標編集
 * @returns
 */
const SectionPositionEditor = ({
  section,
  setSection,
  positions,
  setPositions,
  defaultPositions,
  focusSection,
}) => {
  /**
   * @param {Object.<string, number>} 選択中ブロック内席種の座標
   * @returns {Array}
   */
  const [sectionPosition, setSectionPosition] = useState({ x: 0, y: 0 });

  /**
   * @param {Array.<number>} デフォルト座標
   * @returns {Array}
   */
  const [defaultPosition, setDefaultPosition] = useState([0, 0]);

  /**
   * @param {boolean} 設定されている座標がデフォルト値か
   * @returns {Array}
   */
  const [isDefault, setIsDefault] = useState(true);

  /**
   * 座標の変更を確定
   * @param newSection
   */
  const updatePosition = () => {
    const newSection = update(section, {
      x: { $set: sectionPosition.x },
      y: { $set: sectionPosition.y },
    });
    setSection(newSection);
    focusSection(newSection, FOCUS_SCALE);
    setPositions(
      update(positions, {
        [section.blockId]: {
          [section.id]: { $set: [sectionPosition.x, sectionPosition.y] },
        },
      })
    );
  };

  /**
   * 座標の変更を確定
   * @param {Object.<string, string>} position
   */
  const updatePositionFromState = () => {
    updatePosition(sectionPosition);
  };

  /**
   * 座標の変更を破棄する
   * @param {Object.<string, sting>} position
   */
  const resetPosition = () => {
    const [x = 0, y = 0] = defaultPosition;
    const position = { x, y };
    setSectionPosition(position);
    updatePosition(position);
  };

  /**
   * 座標を変更
   * @param {Event} e
   */
  const handleSectionPositionChange = (e) => {
    const input = e.target;
    setSectionPosition(
      update(sectionPosition, {
        [input.name]: { $set: parseFloat(input.value) },
      })
    );
  };

  /**
   * 選択をキャンセルする
   */
  const cancel = () => {
    setSection(null);
  };

  // 選択ブロック変更時にブロック内席種リストから座標を取得
  useEffect(() => {
    setSectionPosition({
      x: section.x,
      y: section.y,
    });

    const newDefaultPosition = deepFetch(
      defaultPositions,
      section.blockId,
      section.id
    ) ?? [0, 0];
    setDefaultPosition(newDefaultPosition);
    setIsDefault(
      section.x === newDefaultPosition[0] && section.y === newDefaultPosition[1]
    );
  }, [section, defaultPositions]);

  const isChanged =
    section.x !== sectionPosition.x ||
    (section.y !== sectionPosition.y && section.y);

  return (
    <div className="SearchPositionEditor-root">
      <input
        type="number"
        step="0.001"
        min="0"
        max="1"
        className="input input-sm"
        name="x"
        value={sectionPosition.x}
        onChange={handleSectionPositionChange}
      />
      <input
        type="number"
        step="0.001"
        min="0"
        max="1"
        className="input input-sm"
        name="y"
        value={sectionPosition.y}
        onChange={handleSectionPositionChange}
      />
      <div>
        <button
          type="button"
          className="btn btn-sm"
          disabled={!isChanged}
          onClick={updatePositionFromState}
        >
          変更
        </button>
        <button type="button" className="btn btn-sm" onClick={cancel}>
          キャンセル
        </button>
        <button
          type="button"
          className="btn btn-sm"
          disabled={isDefault}
          onClick={resetPosition}
        >
          変更を破棄
        </button>
      </div>
    </div>
  );
};

/**
 * 座標編集
 */
const Edit = () => {
  const history = useHistory();

  // ローカル環境でのみアクセス可能
  if (process.env.NODE_ENV !== 'development') {
    history.replace('');
  }

  /** @var {Object<HtmlElement>} 球場図SVG */
  const sectionMap = useRef(null);

  /** @var {Object<HtmlElement>} 球場図表示エリア (サイズ計算用) */
  const sectionMapArea = useRef(null);

  const [displayArea, setDisplayArea] = useState({
    scale: 1,
    translation: { x: 0, y: 0 },
  });

  /**
   * @param {Section|null} 選択中の席種
   * @returns {Array}
   */
  const [section, setSection] = useState(null);

  /**
   * @param {Object.<string, Object>} 座標リスト
   * @returns {Array}
   */
  const [defaultPositions, setDefaultPositions] = useState({});
  const [positions, setPositions] = useState({});

  /**
   * @param {Element|null} 選択中の席種クリッカブルエリア
   * @returns {Array}
   */
  const [selectedSectionEl, setSelectedSectionEl] = useState(null);

  /**
   * 座標を反映してSectionモデルを作成
   * @param {string} blockId
   * @param {string} sectionId
   * @returns {Section}
   */
  const createSection = (blockId, sectionId) => {
    const newSection = Section.find(blockId, sectionId);
    const [x = null, y = null] = deepFetch(positions, blockId, sectionId) ?? [];
    if (newSection && x !== null && y !== null) {
      newSection.x = x;
      newSection.y = y;
    }
    return newSection;
  };

  /**
   * 席種ハイライト表示を更新
   * @type {Element} selectEl 新しく選択した席種クリッカブルエリア
   */
  const selectSection = (sectionEl) => {
    if (selectedSectionEl) {
      selectedSectionEl.classList.remove('selected');
    }
    const newSectionEl =
      sectionEl && sectionEl.tagName === 'g' ? sectionEl : null;
    setSelectedSectionEl(newSectionEl);
    if (newSectionEl) {
      sectionEl.classList.add('selected');
    }
    setSelectedSectionEl(newSectionEl);
  };

  /**
   * ブロック内席種を球場図で拡大表示する
   * @param selectedSection
   * @param scale
   */
  const focusSection = (selectedSection, scale = 1) => {
    if (!selectedSection) {
      return;
    }

    // 表示座標計算
    const isSp = getMediaQuery() === 'sp';
    const areaRect = sectionMapArea.current.getBoundingClientRect();
    const svgRect = sectionMap.current.getBoundingClientRect();
    const scaleCorrection = scale / displayArea.scale;
    const translation = {
      x:
        svgRect.width * scaleCorrection * -selectedSection.x +
        areaRect.width / 2,
      y:
        svgRect.height * scaleCorrection * -selectedSection.y +
        areaRect.height / 2,
    };

    // レスポンシブ比率補正
    if (isSp) {
      const breakPointCorrection = areaRect.height * 0.95 - areaRect.width - 96;
      if (breakPointCorrection > 0) {
        const centerY = (-svgRect.height * scaleCorrection) / 2;
        const rate = breakPointCorrection / 1200 + 0.28;
        translation.y -= (translation.y - centerY) * rate - 140;
      } else if (breakPointCorrection < -380) {
        const centerX = (-svgRect.width * scaleCorrection) / 2;
        const rate = breakPointCorrection / 800 + 1;
        translation.x -= (translation.x - centerX) * rate - 140;
      }
    }

    setDisplayArea({ scale, translation });
  };

  /**
   * 球場図クリック
   * @param {Event} e
   */
  const handleMapClick = (e) => {
    const sectionEl = e.target.parentNode;
    const code = sectionEl.dataset.name ?? sectionEl.id ?? '';
    const [newBlockId, newSectionId] = code.split('-');
    const newSection = createSection(newBlockId, newSectionId);
    selectSection(sectionEl);
    setSection(newSection);
    focusSection(newSection, FOCUS_SCALE);
  };

  // デフォルト座標の取得
  useLayoutEffect(() => {
    const newDefaultPositions = { ...defaultPositions };
    const newPositions = { ...sectionPositions };
    const baseRect = sectionMapArea.current.getBoundingClientRect();
    setDisplayArea({
      scale: 1,
      translation: { x: 0, y: 0 },
    });

    const areaRect = sectionMapArea.current.getBoundingClientRect();
    const layers = sectionMap.current.querySelectorAll('g > g');
    layers.forEach((layer) => {
      const code = layer.dataset.name ?? layer.id ?? '';
      const [mapBlockId, mapSectionId] = code.split('-');
      const layerRect = layer.getBoundingClientRect();

      const position = [
        Math.round(
          (((layerRect.x - displayArea.translation.x) / displayArea.scale +
            layerRect.width / displayArea.scale / 2 -
            baseRect.x) /
            areaRect.width) *
            1000
        ) / 1000,
        Math.round(
          (((layerRect.y - displayArea.translation.y) / displayArea.scale +
            layerRect.height / displayArea.scale / 2 -
            baseRect.y) /
            areaRect.height) *
            1000
        ) / 1000,
      ];
      deepInit(position, newDefaultPositions, mapBlockId, mapSectionId);

      // 既存のJSONに設定がなければデフォルト値を入力する
      if (!deepFetch(newPositions, mapBlockId, mapSectionId)) {
        deepInit(position, newPositions, mapBlockId, mapSectionId);
      }
    });

    setDefaultPositions(newDefaultPositions);
    setPositions(newPositions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sectionMap]);

  /**
   * すべてのブロック内座席の座標をSVG取得値に変更
   */
  const resetPositions = () => {
    setPositions(defaultPositions);
  };

  /**
   * 座標データをJSONファイルとしてダウンロード
   */
  const download = () => {
    const blob = new Blob([JSON.stringify(positions)], { type: 'text/plan' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'sectionPositions.json';
    link.click();
  };

  return (
    <Layout pageSlug="stadium">
      <div className="Edit-root inner">
        <div className="container container-vm3">
          <div className="map-image" ref={sectionMapArea}>
            <MapInteractionCSS
              value={displayArea}
              onChange={setDisplayArea}
              minScale={1}
              maxScale={5}
              showControls
              plusBtnClass="plus-map-btn"
              minusBtnClass="minus-map-btn"
              controlsClass="map-btn-wrap"
            >
              <img src={sectionMapBg} alt="ベルーナドーム球場図" />
              <SectionMap
                ref={sectionMap}
                className="section-map section-map-selectable"
                onClick={handleMapClick}
              />
            </MapInteractionCSS>
          </div>
          <div className="tab">
            {section ? (
              <div className="pc tab-select">
                <div className="box">
                  <p className="select-label">選択中</p>
                  <div className="box-text">
                    <h3 className="type">
                      {section.id}.{section.name}
                    </h3>
                    <div className="select-desc">
                      <p className="block">{section.blockId}ブロック</p>
                    </div>
                  </div>
                </div>
                <SectionPositionEditor
                  section={section}
                  setSection={setSection}
                  positions={positions}
                  setPositions={setPositions}
                  defaultPositions={defaultPositions}
                  focusSection={focusSection}
                />
              </div>
            ) : (
              <div className="pc tab-search">
                <div className="box-text">
                  <h3 className="box-text-ttl">クリック領域座標設定</h3>
                  <div className="text">
                    <p>
                      球場図からブロック内席種を選択し、座標を設定してください
                    </p>
                  </div>
                  <div className="text">
                    <p>
                      座標の変更後、ブロック内席種の座標をJSONファイルとしてダウンロードします
                    </p>
                    <p>src/data/ ディレクトリ内に上書き保存してください</p>
                    <p>JSONデータを書き換えるまで座標の更新は保存されません</p>
                    <button
                      type="button"
                      className="btn btn-sm btn-regular"
                      onClick={download}
                    >
                      ダウンロード
                    </button>
                  </div>
                  <div className="text">
                    <div className="text">
                      <p>すべてのブロック内座席の座標をSVG取得値に設定します</p>
                      <button
                        type="button"
                        className="btn btn-sm btn-regular"
                        onClick={resetPositions}
                      >
                        全座標リセット
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Edit;
