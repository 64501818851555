import blocks from '../data/blocks.json';
import blockIdAliases from '../data/blockIdAliases.json';

/**
 * 配列・オブジェクトを深掘りする
 * @param data 対象となる配列・オブジェクト
 * @param {Array.<string|Array>} keys キー名 (配列を渡すとOR検索)
 * @returns {*}
 */
const deepFetch = (data, ...keys) => {
  let reference = data;
  for (let keyIndex = 0; keyIndex < keys.length; keyIndex += 1) {
    const key = keys[keyIndex];
    if (!(reference instanceof Object)) {
      return null;
    }

    // 配列でキーを指定した場合、値が取得できるまで再起的呼び出しを行う
    if (key instanceof Array) {
      let value = null;
      for (let i = 0; i < key.length; i += 1) {
        const childKeys = [...keys].splice(keyIndex + 1);
        value = deepFetch(reference, key[i], ...childKeys);
        if (value !== null) {
          break;
        }
      }
      return value;
    }

    if (typeof reference[key] === 'undefined') {
      return null;
    }
    reference = reference[key];
  }
  return reference;
};

/**
 * 深い階層のオブジェクトを初期化する
 * @param defaultValue
 * @param data
 * @param keys
 */
const deepInit = (defaultValue, data, ...keys) => {
  let reference = data;
  keys.forEach((key, index) => {
    if (typeof reference[key] === 'undefined') {
      if (index === keys.length - 1) {
        reference[key] = defaultValue;
      } else {
        reference[key] = {};
      }
    }
    reference = reference[key];
  });
};

/**
 * 引数の数に応じてデータ検索
 * @param {string} blockId ブロックID
 * @param {string|null} [rowId] 段数
 * @param {string|null} [seatId] 座席番号
 * @returns {Object} 引数1でブロック、引数3で席種のデータを返す
 */
const fetchSingleData = (blockId, rowId = null, seatId = null) => {
  const sections = blocks[blockId];
  if (sections) {
    // 引数1つでブロック内席種を返す
    if (rowId === null) {
      return sections;
    }
    // ブロック内のすべての席種を走査する
    const sectionIds = Object.keys(sections);
    for (let i = 0; i < sectionIds.length; i += 1) {
      const sectionId = sectionIds[i];
      const seats = deepFetch(sections[sectionId], rowId);
      if (seats && seats.includes(seatId)) {
        return { blockId, sectionId };
      }
    }
  }
  return {};
};

/**
 * 引数の数に応じてデータ検索 (ブロック空欄を解決する)
 * @param {string} blockId ブロックID
 * @param {string|null} [rowId] 段数
 * @param {string|null} [seatId] 座席番号
 * @returns {Object}
 */
const fetchData = (blockId, rowId = null, seatId = null) => {
  const blockNameAlias = blockIdAliases[blockId];

  if (blockNameAlias) {
    let result = {};
    blockNameAlias.some((aliasBlockId) => {
      result = fetchSingleData(aliasBlockId, rowId, seatId);
      return Object.keys(result).length;
    });
    return result;
  }
  return fetchSingleData(blockId, rowId, seatId);
};

/**
 * 座席系IDの入力揺れを補正する
 * @param input
 * @returns {string}
 */
const parseInput = (input) =>
  input
    .replace(/\s+/g, '')
    .replace(/[ー‐‑–—―−ｰ]/g, '-')
    .replace(/[Ａ-Ｚａ-ｚ０-９]/g, (char) =>
      String.fromCharCode(char.charCodeAt(0) - 0xfee0)
    )
    .toUpperCase();

/**
 * ブロックIDにエイリアスがあれば取得する
 * @param {string} blockId
 * @returns {string|null} ブロックエイリアスIDかNULL
 */
const getBlockIdAlias = (blockId) => {
  let aliasBlockId;
  Object.entries(blockIdAliases).some(([targetAliasId, blockIds]) => {
    if (blockIds.includes(blockId)) {
      aliasBlockId = targetAliasId;
      return true;
    }
    return false;
  });
  return aliasBlockId ?? null;
};

export { deepFetch, deepInit, fetchData, parseInput, getBlockIdAlias };
