import { parseInput } from '../utils/dataFetch';

const Input = ({ id, setId }) => (
  <input
    type="text"
    value={id}
    className="box-input"
    placeholder="入力してください"
    onChange={(e) => setId(e.currentTarget.value)}
    onBlur={(e) => setId(parseInput(e.currentTarget.value))}
  />
);

export default Input;
