import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Layout from '../components/Layout';
import icon1 from '../images/icon-lions.svg';
import ticket from '../images/icon-ticket.svg';
import stadium from '../images/icon-stadium.svg';
import SearchModalContent from '../components/SearchModalContent';
import { toggleBodyFixed } from '../utils/domUtil';
import {
  TICKET_PURCHASE_BTN,
  TICKET_PURCHASE_URL,
  MAP_PDF_URL,
} from '../utils/constants';

const Home = () => {
  const location = useLocation();
  const isModalShownState = location.state
    ? location.state.isModalShown
    : false;
  const [isModalShown, setIsModalShow] = useState(isModalShownState);

  // 戻るボタン使用時にスクロール固定解除
  if (!isModalShown) {
    toggleBodyFixed(false);
  }

  return (
    <Layout pageSlug="home">
      <section>
        <div className="inner">
          <div className="inner-sp">
            <div className="emblem">
              <div className="image">
                <img src={icon1} alt="埼玉西武ライオンズ" />
              </div>
              <div className="ttl">
                <h2 className="ttl-main">埼玉西武ライオンズ</h2>
                <p className="ttl-sub">座席案内</p>
              </div>
            </div>
            <div className="search">
              <div className="search-wrap">
                <button
                  className="search-btn"
                  onClick={() => setIsModalShow(true)}
                  type="button"
                >
                  <div className="modal-link">
                    <p>
                      観戦チケットを
                      <br className="sp" />
                      お持ちの方向け
                    </p>
                    <div className="search-flex">
                      <div className="image">
                        <img src={ticket} alt="チケット" />
                      </div>
                      <p className="text">
                        座席番号
                        <br />
                        <span>から調べる</span>
                      </p>
                    </div>
                  </div>
                </button>
                <button className="search-btn" type="button">
                  <Link to="/seat">
                    <p>
                      これからチケットを
                      <br className="sp" />
                      購入される方向け
                    </p>
                    <div className="search-flex">
                      <div className="image">
                        <img src={stadium} alt="球場図" />
                      </div>
                      <p className="text">
                        球場図
                        <br />
                        <span>から調べる</span>
                      </p>
                    </div>
                  </Link>
                </button>
              </div>
            </div>
          </div>
          <div className="link-wrap">
            <ul>
              <li>
                <a
                  href="https://www.seibulions.jp/stadium/access/"
                  target="_blank"
                  rel="noopener"
                >
                  ベルーナドームへのアクセスはこちら
                </a>
              </li>
              {MAP_PDF_URL && (
                <li>
                  <a href={MAP_PDF_URL} target="_blank" rel="noopener">
                    ベルーナドーム座席図PDF DOWNLOAD
                  </a>
                </li>
              )}
              {TICKET_PURCHASE_BTN && (
                <li>
                  <a href={TICKET_PURCHASE_URL} target="_blank" rel="noopener">
                    チケットの購入はこちら
                  </a>
                </li>
              )}
            </ul>
          </div>
        </div>
      </section>
      {isModalShown && <SearchModalContent setIsShown={setIsModalShow} />}
    </Layout>
  );
};

export default Home;
